<template>
  <div class="mx-3">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- top button -->
    <div v-if="!isLoading">
      <div
        class="d-flex gap-3 justify-content-end align-items-end mx-3 align-content-end flex-wrap"
      >
        <b-form-select
          v-model="selectedCategory"
          :options="categoryOptions"
          size="sm"
          class="select-category mt-3"
        ></b-form-select>
        <b-form-select
          v-model="selectedYear"
          :options="yearOptions"
          size="sm"
          class="select-year mt-3"
          @change="changeYear(selectedYear)"
        ></b-form-select>
        <button class="btn-export" @click="exportExcel()">
          <div
            class="d-flex gap-2 w-100 justify-content-center align-items-center"
          >
            <svg
              width="17"
              height="19"
              viewBox="0 0 17 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 13.5C1.76522 13.5 2.01957 13.6054 2.20711 13.7929C2.39464 13.9804 2.5 14.2348 2.5 14.5V16.5H14.5V14.5C14.5 14.2348 14.6054 13.9804 14.7929 13.7929C14.9804 13.6054 15.2348 13.5 15.5 13.5C15.7652 13.5 16.0196 13.6054 16.2071 13.7929C16.3946 13.9804 16.5 14.2348 16.5 14.5V16.5C16.5 17.0304 16.2893 17.5391 15.9142 17.9142C15.5391 18.2893 15.0304 18.5 14.5 18.5H2.5C1.96957 18.5 1.46086 18.2893 1.08579 17.9142C0.710714 17.5391 0.5 17.0304 0.5 16.5V14.5C0.5 14.2348 0.605357 13.9804 0.792893 13.7929C0.98043 13.6054 1.23478 13.5 1.5 13.5Z"
                fill="white"
              />
              <path
                d="M7.78071 0.792786C7.96824 0.605315 8.22255 0.5 8.48771 0.5C8.75288 0.5 9.00718 0.605315 9.19471 0.792786L13.1947 4.79279C13.3769 4.98139 13.4777 5.23399 13.4754 5.49619C13.4731 5.75838 13.3679 6.0092 13.1825 6.1946C12.9971 6.38001 12.7463 6.48518 12.4841 6.48746C12.2219 6.48974 11.9693 6.38894 11.7807 6.20679L9.48771 3.91379V12.4998C9.48771 12.765 9.38235 13.0194 9.19482 13.2069C9.00728 13.3944 8.75293 13.4998 8.48771 13.4998C8.2225 13.4998 7.96814 13.3944 7.78061 13.2069C7.59307 13.0194 7.48771 12.765 7.48771 12.4998V3.91379L5.19471 6.20679C5.00611 6.38894 4.75351 6.48974 4.49131 6.48746C4.22911 6.48518 3.9783 6.38001 3.79289 6.1946C3.60749 6.0092 3.50232 5.75838 3.50004 5.49619C3.49776 5.23399 3.59855 4.98139 3.78071 4.79279L7.78071 0.792786Z"
                fill="white"
              />
            </svg>
            {{ $t("maintenance_report.export") }}
          </div>
        </button>
      </div>
      <div class="mx-3">
        <!-- count card -->
        <div class="my-3 mx-3">
          <!-- <div class="row justify-content-center gap-2"> -->
          <div class="row justify-content-around ">
            <template v-for="(status, key) in statusCountCard">
              <CountCard :key="key" :statusCountCard="status" />
            </template>
          </div>
        </div>

        <div
          class="chart d-flex flex-wrap my-3 justify-content-around align-items-center gap-4 w-100"
        >
          <!-- donut chart -->
          <div class="donut-chart my-2">
            <apexchart
              width="100%"
              type="donut"
              :options="donutOptions"
              :series="donutSeries"
            ></apexchart>
            <!-- donut chart-legend -->
            <div
              class="donut-legend d-flex justify-content-between mt-3"
              v-for="(item, index) in donutOptions.labels"
              :key="index"
            >
              <div class="d-flex align-items-center gap-1">
                <span
                  class="dots"
                  :class="item"
                  :style="{ backgroundColor: donutOptions.colors[index] }"
                ></span>
                <span class="legend-item">{{
                  $t(`maintenance_report.${item}`)
                }}</span>
              </div>
              <span class="item-amount"
                >{{ donutSeries[index] }} {{ $t("maintenance_report.pieces") }}
              </span>
            </div>
          </div>

          <!-- bar chart -->
          <div class="bar-chart my-2">
            <p class="bar-label">
              {{ $t("maintenance_report.reportitem") }} :
              {{ $t("maintenance_report.monthly") }}
            </p>
            <apexchart
              :options="barOptions"
              :series="barSeries"
              width="100%"
              height="400"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pending from "@/assets/Maintenancing/pending_dashboard.png";
import In_progress from "@/assets/Maintenancing/in-progress_dashboard.png";
import Complete from "@/assets/Maintenancing/maintenance_check.svg";
import Cancel from "@/assets/Maintenancing/cancel_dashboard.png";
import Reject from "@/assets/Maintenancing/maintenance_broken.svg";
import Loading from "vue-loading-overlay";
import Other from "@/assets/Maintenancing/other_dashboard.png";
import store from "../../store";

import CountCard from "@/components/Maintenance/MaintenanceDashboardCountCard.vue";
import axios from "axios";
import { authHeader } from "../../store/actions";
import baseUrl from "@/util/backend";

export default {
  components: {
    CountCard,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      category: {},
      statusCountCard: {
        pending: {
          name: this.$t("maintenance_report.pending"),
          value: "pending",
          icon: Pending,
          count: null,
        },
        in_progress: {
          name: this.$t("maintenance_report.in_progress"),
          value: "in_progress",
          icon: In_progress,
          count: null,
        },
        complete: {
          name: this.$t("maintenance_report.complete"),
          value: "complete",
          icon: Complete,
          count: null,
        },
        cancel: {
          name: this.$t("maintenance_report.cancel"),
          value: "cancel",
          icon: Cancel,
          count: null,
        },
        reject: {
          name: this.$t("maintenance_report.reject"),
          value: "reject",
          icon: Reject,
          count: null,
        },
        // other: {
        //   name: this.$t("maintenance_report.other"),
        //   value: "other",
        //   icon: Other,
        //   count: null,
        // },
      },
      dataFromAPI: null,
      id_company: "",
      selectedCategory: "0",
      selectedYear: new Date().getFullYear(),
      categoryOptions: [
        { value: "0", text: this.$t("maintenance_report.category-all") },
      ],
      yearOptions: [],
      // for donut chart
      donutOptions: {
        responsive: [
          {
            breakpoint: 400, //apply whaen < 819
            options: {
              dataLabels: {
                style: {
                  fontSize: "10px",
                  fontWeight: 250,
                },
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        showAlways: true,
                        fontSize: "16px", //label size
                        fontFamily: "Mitr, sans-serif",
                        fontWeight: 300,
                        label: this.$t("maintenance_report.all"),
                        color: "#0065bd",
                        customText: this.$t("maintenance_report.pieces"),
                        formatter: function(w) {
                          return (
                            w.globals.seriesTotals.reduce((a, b) => {
                              return a + b;
                            }, 0) +
                            " " +
                            this.customText
                          );
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        ],
        chart: {
          fontFamily: "Mitr, sans-serif",
        },
        dataLabels: {
          style: {
            fontSize: "14px",
            fontWeight: 300,
          },
        },
        legend: {
          show: false,
        },
        labels: ["pending", "in_progress", "complete", "cancel", "reject"],
        colors: ["#7c7c7c", "#e39600", "#00ab1b", "#000000", "#ed1616"],
        //total at center
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "20px",
                  fontFamily: "Mitr, sans-serif",
                  fontWeight: 300,
                  label: this.$t("maintenance_report.all"),
                  color: "#0065bd",
                  margin: 5,
                  customText: this.$t("maintenance_report.pieces"),
                  formatter: function(w) {
                    return (
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) +
                      " " +
                      this.customText
                    );
                  },
                },
              },
            },
          },
        },
      },
      donutSeries: [], //m
      //bar chart
      barOptions: {
        responsive: [
          {
            breakpoint: 480, //apply whaen < 819
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: "13px",
                    fontWeight: 300,
                  },
                },
              },
              legend: {
                fontSize: "13px",
                fontWeight: 300,
                position: "top",
              },
            },
          },
        ],
        chart: {
          fontFamily: "Mitr, sans-serif",
          width: "100%",
          type: "bar",
        },
        dataLabels: {
          formatter: function (val) {
            return val > 0.01 ? val : '';
          },
          style: {
            fontSize: "12px",
            colors: ["#000000"],
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "16px",
              fontWeight: 300,
            },
          },
          categories: [
            this.$t("maintenance_report.jan"),
            this.$t("maintenance_report.feb"),
            this.$t("maintenance_report.mar"),
            this.$t("maintenance_report.apr"),
            this.$t("maintenance_report.may"),
            this.$t("maintenance_report.jun"),
            this.$t("maintenance_report.jul"),
            this.$t("maintenance_report.aug"),
            this.$t("maintenance_report.sep"),
            this.$t("maintenance_report.oct"),
            this.$t("maintenance_report.nov"),
            this.$t("maintenance_report.dec"),
          ],
        },
        legend: {
          fontSize: "16px",
          fontWeight: 300,
          position: "top",
        },
      },
      barSeries: [],
    };
  },
  async created() {
    await this.$store.dispatch("loadOptionChoice");
    for (let i = 2023; i <= this.selectedYear; i++) {
      this.yearOptions.push({
        value: i,
        text: i,
      });
    }
    this.category = this.$store.getters["getfilterOption"];
    this.category.optionCategory.forEach((element) => {
      this.categoryOptions.push({
        value: element.id_category,
        text: element.name,
      });
    });
  },
  async mounted() {
    this.getDashboard();
  },
  watch: {
    selectedCategory() {
      this.isLoading = true;
      this.getDashboard();
    },
  },
  methods: {
    async exportExcel() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${baseUrl()}maintain/year/summary/excel`, {
          params: {
            id_company: this.id_company,
            year: this.selectedYear,
            category: this.selectedCategory,
          },
          ...authHeader(),
          responseType: "blob", // set response type to blob
        });

        // create URL for blob
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // create link to download file
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "summary.xlsx");
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error);
        }
        console.log(error);
      }
    },

    //{{baseUrl}}/maintain/year/summary?id_company=1
    //{{baseUrl}}/maintain/year/summary?id_company=1&year=2023
    async getDashboard(years) {
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(`${baseUrl()}maintain/year/summary`, {
          params: {
            id_company: this.id_company,
            year: years,
            category: this.selectedCategory,
          },
          ...authHeader(),
        });
        this.dataFromAPI = res.data.message;
                //define count donut-chart
        Object.entries(this.statusCountCard).forEach(([key, value]) => {
          value.count = res.data.message.status_summary[key];
          if (
            res.data.message.status_summary[key] === undefined ||
            res.data.message.status_summary[key] === null
          ) {
            value.count = 0;
          }
        });
        //define legend donut-chart
        this.donutSeries = [
          this.dataFromAPI.status_summary.pending,
          this.dataFromAPI.status_summary.in_progress,
          this.dataFromAPI.status_summary.complete,
          this.dataFromAPI.status_summary.cancel,
          this.dataFromAPI.status_summary.reject,
        ];

        const total_max = Math.max(
          ...this.dataFromAPI.month_summary.map((item) => item.total_maintain)
        );
        const complete_max = Math.max(
          ...this.dataFromAPI.month_summary.map((item) => item.complete_maintain)
        );

        this.barSeries = [
          {
            data: this.dataFromAPI.month_summary.map((item) =>
              item.total_maintain == 0 && total_max != 0 ? 0.01 : item.total_maintain
            ),
            name: this.$t("maintenance_report.amount"),
            color: "#FEA800",
          },
          {
            data: this.dataFromAPI.month_summary.map((item) =>
              item.complete_maintain == 0 && complete_max != 0 ? 0.01 : item.complete_maintain
            ),
            name: this.$t("maintenance_report.per-piece"),
            color: "#65DF72",
          },
        ];
        console.log(this.barSeries);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error);
        }
        console.log(error);
      }
    },
    changeYear(a) {
      this.getDashboard(a);
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-label {
  text-align: left;
  font-weight: 400;
  font-size: 20px;
}
.bar-chart {
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 10px 15px;
  max-width: 80%;
}
.donut-chart {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 30px 35px;
  max-width: 100%;
}
.select-category {
  color: #7c7c7c;
  background-image: url("../../assets/Maintenancing/maintenance-drop-arrow.svg");
  background-repeat: no-repeat;
  background-position: 93% 50%;
  font-weight: 400;
  font-size: 16px;
  width: fit-content;
  padding: 10px 30px 10px 10px;
  border: 1px solid #7c7c7c !important;
  border-radius: 8px;
}
.select-year {
  color: #7c7c7c;
  background-image: url("../../assets/Maintenancing/maintenance-drop-arrow.svg");
  background-repeat: no-repeat;
  background-position: 93% 50%;
  font-weight: 400;
  font-size: 16px;
  width: 100px;
  padding: 10px 20px;
  border: 1px solid #7c7c7c !important;
  border-radius: 8px;
}
.btn-export {
  border: none;
  background-color: #017afd;
  border-radius: 8px;
  color: #ffffff;
  width: 120px;
  padding: 10px 20px;
}
.dots {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #c4e84a 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
}
@media (min-width: 1023px) {
  //edit when screen is more than 1024px
  .bar-chart {
    width: 65%;
    max-height: 100%;
    padding: 35px 40px;
  }
  .donut-chart {
    padding: 50px 50px;
    min-width: 22%;
  }
}
// @media (max-width: 1811px) {
//   //edit when screen is more than 1024px
//   .chart{
//     justify-content: center !important;
//   }
// }
</style>
